// var TTS = {
//     URL: 'https://tts-ui.techtrans.live/article-details.html',
//     TITLE: 'شارك تعليم لأول مرة في العالم العربي إطلاق منصة دورات تقنية عربية للمنظمات غير الربحية', // Title...
//     DESCRIPTION: 'شارك تعليم لأول مرة في العالم العربي إطلاق منصة دورات تقنية عربية للمنظمات غير الربحية 29 يوليو , 2022 في بادرة هي الأولى من نوعها في الشرق الأوسط تطلق منظمة تكسوب بالشراكة مع التحول التقني منصة دورات تقنية باللغة العربية موجهة للقطاع غير الربحي.', //desciption...
//     IMAGE: '', //url image here...
//     FB_APP_ID: '150778729046724',
// }
// document.getElementById('fbButton').addEventListener('click', function() {
//     VanillaSharing.fbButton({
//         url: TTS.URL,
//     });
// });
// document.getElementById('tw').addEventListener('click', function() {
//     VanillaSharing.tw({
//         url: TTS.URL,
//         title: TTS.TITLE,
//         hashtags: ['text', 'text'],
//     });
// });
// document.getElementById('whatsapp').addEventListener('click', function() {
//     VanillaSharing.whatsapp({
//         url: TTS.URL,
//         title: TTS.TITLE,
//     });
// });
// document.getElementById('telegram').addEventListener('click', function() {
//     VanillaSharing.telegram({
//         url: TTS.URL,
//         title: TTS.TITLE,
//         description: TTS.DESCRIPTION,
//     });
// });

["fbButton", "tw", "whatsapp", "telegram"].forEach((id) => {
    const element = document.getElementById(id);
    if (element) {
      element.addEventListener("click", function () {
        switch (id) {
          case "fbButton":
            VanillaSharing.fbButton({ url: 'https://tts-ui.techtrans.live/article-details.html' });
            break;
          case "tw":
            VanillaSharing.tw({
              url: TTS.URL,
              title: TTS.TITLE,
              hashtags: ["text", "text"],
            });
            break;
          case "whatsapp":
            VanillaSharing.whatsapp({ url: TTS.URL, title: TTS.TITLE });
            break;
          case "telegram":
            VanillaSharing.telegram({
              url: TTS.URL,
              title: TTS.TITLE,
              description: TTS.DESCRIPTION,
            });
            break;
        }
      });
    }
  });
  